import React from "react";

import SVG from "../Svg";

const Plus = ({ className }: { className?: string }) => (
  <SVG
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    >
         <path
            d="M5.9938 7.30532H6.4938V6.80532L6.4938 1.7027C6.4938 1.50916 6.6507 1.35226 6.84424 1.35226C7.03778 1.35226 7.19467 1.50916 7.19467 1.7027L7.19468 6.80532V7.30532H7.69468L12.7973 7.30532C12.9908 7.30532 13.1477 7.46221 13.1477 7.65575C13.1477 7.84929 12.9908 8.00619 12.7973 8.00619L12.7973 8.50619L12.7973 8.00619L7.69468 8.00619H7.19468V8.50619L7.19467 13.6088C7.19467 13.8023 7.03778 13.9592 6.84424 13.9592C6.6507 13.9592 6.4938 13.8023 6.4938 13.6088L6.4938 8.50619V8.00619H5.9938L0.891185 8.00619C0.697644 8.00619 0.540748 7.84929 0.540748 7.65575C0.540748 7.46221 0.697643 7.30532 0.891184 7.30532L5.9938 7.30532Z"
            fill="#C2F051"
            stroke="currentColor"
        />
    </SVG>
);

export default Plus;

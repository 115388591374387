import React from 'react'
import { HASHEX_URL } from '../../constants/links'
import GoogleTagService from '../../services/gtag.service'

import * as s from './Header.styled'

const DropdownMenu = ({ links }: { links: any[] }) => (
  <s.Dropdown>
    {links?.map((item) => {
      const isExternal = item.linkHref.includes('http')

      const handleClick = () =>
        GoogleTagService.pushNavigationClick(item.linkTitle)

      const props = isExternal
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: item.linkHref,
          }
        : {
            onClick: handleClick,
            href: HASHEX_URL + item.linkHref,
          }

      return (
        <s.DropdownItem key={item.id}>
          <s.MenuDropdownLink {...props}>{item.linkTitle}</s.MenuDropdownLink>
        </s.DropdownItem>
      )
    })}
  </s.Dropdown>
)

export default DropdownMenu

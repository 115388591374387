/** @format */

export const common = {
  auditsTitle: 'Audits',
  servicesTitle: 'Services',
  productsTitle: 'Products',
  chainsTitle: 'Chains',
  companyTitle: 'Company',
  typesOfAuditsTitle: 'Types of Audits',
  telegramChatTitle: `Let's Talk`,
  copyrightText: '© All rights reserved',
  privacyPolicyTitle: 'Privacy Policy',
  cookiePolicyTitle: 'Cookie Policy',
  telegramChatLink: 'https://t.me/hashex_manager',
  socialMediumLink: 'https://blog.hashex.org/',
  socialTwitterLink: 'https://twitter.com/hashexofficial',
  socialLinkedinLink:
    'https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fhashex%2Fabout%2F',
  socialGithubLink: 'https://github.com/HashEx',
  socialEmailLink: 'contact@hashex.org',
  socialTelegramLink: 'https://t.me/HashExOfficial',
  privacyHref: `/privacy`,
  cookieHref: `/cookie`,
  chainsHref: `/chains`,
  typesOfAuditsHref: `/audit-types`,
  productsHref: `/products`,
  servicesHref: `/services`,
  socialAnalytexEmailLink: 'contact@analytex.org',
  defiBadgeLink: 'https://defisec.info/',
  auditsLinks: [
    {
      id: 1,
      linkTitle: 'Smart Contract Audit',
      linkHref: `/smart-contract-audit`,
    },

    { id: 51, linkTitle: 'Audit Reports', linkHref: `/audits` },
  ],
  chainsLinks: [
    { id: 25, linkTitle: 'BSC', linkHref: `/chains/bsc` },
    { id: 26, linkTitle: 'Fantom', linkHref: `/chains/fantom` },
    { id: 27, linkTitle: 'Solana', linkHref: `/chains/solana` },
    {
      id: 28,
      linkTitle: 'Avalanche',
      linkHref: `/chains/avalanche`,
    },
    { id: 29, linkTitle: 'Aurora', linkHref: `/chains/aurora` },
    { id: 30, linkTitle: 'Polygon', linkHref: `/chains/polygon` },
    {
      id: 31,
      linkTitle: 'Gnosis Chain',
      linkHref: `/chains/gnosis`,
    },
    { id: 57, linkTitle: 'Terra', linkHref: `/chains/terra` },
    { id: 58, linkTitle: 'TON', linkHref: `/chains/ton` },
    { id: 59, linkTitle: 'NEAR', linkHref: `/chains/near` },
    { id: 60, linkTitle: 'Hedera', linkHref: `/chains/hedera` },
    {
      id: 61,
      linkTitle: 'Ethereum',
      linkHref: `/chains/ethereum`,
    },
    { id: 62, linkTitle: 'Neon', linkHref: `/chains/neon` },
    { id: 63, linkTitle: 'Shardeum', linkHref: `/chains/shardeum` },
  ],
  companyLinks: [
    {
      id: 17,
      linkTitle: 'About us',
      linkHref: `/company/about-us`,
    },
    { id: 55, linkTitle: 'Media', linkHref: `/company/media` },
    { id: 19, linkTitle: 'Careers', linkHref: `/company/careers` },
    {
      id: 20,
      linkTitle: 'Customers',
      linkHref: `/company/customers`,
    },
    {
      id: 21,
      linkTitle: 'Partners',
      linkHref: `/company/partners`,
    },
    { id: 22, linkTitle: 'Blog', linkHref: `/company/blog` },
    { id: 23, linkTitle: 'FAQ', linkHref: `/faq` },
    { id: 24, linkTitle: 'Contact us', linkHref: `/contacts` },
  ],
  productsLinks: [
    {
      id: 11,
      linkTitle: 'AnalytEx',
      linkHref: `/products/analytex`,
    },
    {
      id: 12,
      linkTitle: 'CryptEx',
      linkHref: `/products/cryptex`,
    },
    {
      id: 13,
      linkTitle: 'Online ABI Encoder',
      linkHref: 'https://abi.hashex.org/',
    },
    {
      id: 14,
      linkTitle: 'Paper Wallet',
      linkHref: 'https://paper.hashex.org/',
    },
  ],
  servicesLinks: [
    {
      id: 4,
      linkTitle: 'Security Audits',
      linkHref: `/smart-contract-audit`,
    },
    {
      id: 5,
      linkTitle: 'Smart Contract Development',
      linkHref: `/services/smart-contract-development`,
    },
    {
      id: 6,
      linkTitle: 'Blockchain consulting',
      linkHref: `/services/blockchain-consulting`,
    },
    {
      id: 8,
      linkTitle: 'Blockchain risk analysis',
      linkHref: `/services/blockchain-risk-analysis`,
    },
    {
      id: 9,
      linkTitle: 'Blockchain Integration',
      linkHref: `/services/blockchain-integration`,
    },
    {
      id: 10,
      linkTitle: 'Blockchain developer',
      linkHref: `/services/blockchain-development`,
    },
    {
      id: 62,
      linkTitle: 'NFT Marketplace Development',
      linkHref: `/services/nft-marketplace-development`,
    },
    {
      id: 63,
      linkTitle: 'Penetration Testing',
      linkHref: `/services/penetration-testing`,
    },

    {
      id: 64,
      linkTitle: 'Incident response',
      linkHref: `/services/incident-response`,
    },
    {
      id: 65,
      linkTitle: 'HR services',
      linkHref: `/services/hr-service`,
    },
    {
      id: 67,
      linkTitle: 'Marketing services',
      linkHref: `/services/marketing-service`,
    },
  ],
  typesOfAuditsLinks: [
    {
      id: 32,
      linkTitle: 'NFT audit',
      linkHref: `/audit-types/nft-audit`,
    },
    {
      id: 33,
      linkTitle: 'Staking audit',
      linkHref: `/audit-types/staking-audit`,
    },
    {
      id: 34,
      linkTitle: 'Stablecoin audit',
      linkHref: `/audit-types/stablecoin-audit`,
    },
    {
      id: 37,
      linkTitle: 'RFI token audit',
      linkHref: `/audit-types/rfi-token-audit`,
    },
    {
      id: 38,
      linkTitle: 'BabyToken audit',
      linkHref: `/audit-types/babytoken-audit`,
    },
    {
      id: 40,
      linkTitle: 'DAO audit',
      linkHref: `/audit-types/dao-audit`,
    },
    {
      id: 35,
      linkTitle: 'DApp audit',
      linkHref: `/audit-types/dapp-audit`,
    },
    {
      id: 36,
      linkTitle: 'DEX audit',
      linkHref: `/audit-types/dex-audit`,
    },
    {
      id: 53,
      linkTitle: 'Lending audit',
      linkHref: `/audit-types/lending-audit`,
    },
    {
      id: 41,
      linkTitle: 'erc20, erc721, erc1155 audit',
      linkHref: `/audit-types/erc-tokens-audit`,
    },
    {
      id: 39,
      linkTitle: 'Farm audit',
      linkHref: `/audit-types/farm-audit`,
    },
    {
      id: 54,
      linkTitle: 'Bridge audit',
      linkHref: `/audit-types/bridge-audit`,
    },
    {
      id: 42,
      linkTitle: 'Marketplace, auction, game audit',
      linkHref: `/audit-types/marketplace-audit`,
    },
  ],
  contacts: {
    id: 1,
    title: "Let's Work Together! Write now.",
    requestButtonText: 'Request a Quote',
    requestButtonHref: `/contacts`,
    email: 'contact@hashex.org',
  },
}

import React from "react";

import SVG from "../Svg";

const TelegramIcon = () => (
  <SVG width="17" height="14" fill="none">
    <path
      d="m16.953 1.296-2.578 11.843c-.203.844-.67 1.068-1.403.67l-3.906-2.83-1.897 1.788c-.252.248-.518.372-.796.372l.303-3.91 7.244-6.406c.177-.174.177-.274 0-.298-.152-.05-.316-.013-.493.111l-8.95 5.55L.609 6.994c-.43-.124-.632-.31-.607-.559.025-.248.29-.46.796-.633L15.854.067c.379-.125.682-.075.91.148.227.2.29.56.19 1.08Z"
      fill="currentColor"
    />
  </SVG>
);

export default TelegramIcon;

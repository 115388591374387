export class GoogleTagService {
    static emitEventAction(id: string, action: string) {
        if (typeof window !== 'undefined' && (window as any).gtag) {
            const { gtag } = window as any;
            gtag('event', id, {
                event_category: 'Elements',
                event_action: action,
            });
        }
    }

    static pushDataLayer(event: string, args: any) {
        if (typeof window !== 'undefined' && (window as any).dataLayer) {
            const { dataLayer } = window as any;

            dataLayer.push({
                event,
                ...args,
            });
        } else {
            console.log('pushDataLayer', { event, ...args });
        }
    }

    static getOriginalLocation() {
        if (typeof document !== 'undefined' && document.location) {
            const { protocol, hostname, pathname, search } = document.location;
            return `${protocol}//${hostname}${pathname}${search}`;
        }
        return '';
    }

    static pushPageViewToDataLayer(title: string, path: string) {
        GoogleTagService.pushDataLayer('Pageview', {
            pagePath: path,
            pageTitle: title, // some arbitrary name for the page/state
            originalLocation: GoogleTagService.getOriginalLocation(),
        });
    }

    static pushRequestAuditToDataLayer(args: any) {
        GoogleTagService.pushDataLayer('request_contract', args);
    }

    static pushRequestAuditClick(url: string = window.location.pathname) {
        GoogleTagService.pushRequestAuditToDataLayer({
            eventCategory: 'elements',
            eventAction: 'click',
            eventLabel: url, // for label we must used pagepath in this event
        });
    }

    static pushRequestAuditSent(services: string) {
        GoogleTagService.pushRequestAuditToDataLayer({
            eventCategory: 'audit_form',
            eventAction: 'send',
            eventLabel: services,
        });
    }

    static pushRequestAuditError(services: string) {
        GoogleTagService.pushRequestAuditToDataLayer({
            eventCategory: 'audit_form',
            eventAction: 'error',
            eventLabel: services,
        });
    }

    static pushRequestPartnershipSent(url: string) {
        GoogleTagService.pushRequestAuditToDataLayer({
            eventCategory: 'partnership_form',
            eventAction: 'send',
            eventLabel: url,
        });
    }

    static pushRequestPartnershipError(url: string) {
        GoogleTagService.pushRequestAuditToDataLayer({
            eventCategory: 'partnership_form',
            eventAction: 'error',
            eventLabel: url,
        });
    }

    static pushSocialMediaClick(social: string) {
        if (social) {
            GoogleTagService.pushDataLayer('social_media', {
                eventCategory: 'elements',
                eventAction: 'click',
                eventLabel: social,
            });
        }
    }

    static pushProductClick(product: string) {
        if (product) {
            GoogleTagService.pushDataLayer('products', {
                eventCategory: 'elements',
                eventAction: 'click',
                eventLabel: product,
            });
        }
    }

    static pushNavigationClick(item: string) {
        if (item) {
            GoogleTagService.pushDataLayer('navigations', {
                eventCategory: 'elements',
                eventAction: 'click',
                eventLabel: item,
            });
        }
    }
}

export default GoogleTagService;

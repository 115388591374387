import React, { useMemo } from 'react'
import logo from '../../images/logo-footer.svg'

import { getSocial } from './socials'

import * as s from './Footer.styled'
import { HASHEX_URL } from '../../constants/links'

const isEmail = (href: string): boolean =>
  // eslint-disable-next-line
  /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
    href,
  )

const titleComponent = (title: string) => {
  if (title === 'Services') {
    return <s.TitleLink href={`${HASHEX_URL}/services`}>{title}</s.TitleLink>
  }

  if (title === 'Products') {
    return <s.TitleLink href={`${HASHEX_URL}/products`}>{title}</s.TitleLink>
  }

  return title
}

const linksComponent = ({ title, links }: { title: string; links: any[] }) => (
  <s.Block>
    <s.Title>{titleComponent(title)}</s.Title>

    <s.Links>
      {links?.map((item) => {
        const isExternal = item.linkHref.includes('http')

        return (
          <s.LinksItem key={item?.id}>
            {isExternal ? (
              <s.Link
                href={item?.linkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item?.linkTitle}
              </s.Link>
            ) : (
              <s.Link href={item?.linkHref}>{item?.linkTitle}</s.Link>
            )}
          </s.LinksItem>
        )
      })}
    </s.Links>
  </s.Block>
)

export const PoliciesComponent = ({ common }: { common: any }) => (
  <s.PolicyList>
    <s.PolicyLinks>
      <s.Policy>{common?.copyrightText}</s.Policy>
      <s.Policy>
        <s.PolicyLink href={`${HASHEX_URL}/privacy`}>
          {common?.privacyPolicyTitle}
        </s.PolicyLink>
      </s.Policy>
      <s.Policy>
        <s.PolicyLink href={`${HASHEX_URL}/cookie`}>
          {common?.cookiePolicyTitle}
        </s.PolicyLink>
      </s.Policy>
    </s.PolicyLinks>
    <s.AdressBlock>
      <s.DSAMemberBadge
        href={common?.defiBadgeLink}
        target="_blank"
        rel="nofollow noopener noreferrer"
      />
      <s.CompanyAdress>
        HashEx US, LLC&nbsp;
        <s.DesktopHyphenation />
        16192 COASTAL&nbsp;
        <s.Hyphenation />
        HWY -&nbsp;
        <s.DesktopHyphenation />
        LEWES DE&nbsp;19958, USA
      </s.CompanyAdress>
    </s.AdressBlock>
  </s.PolicyList>
)

const Footer = ({ common }: { common: any }) => {
  const socials = useMemo(() => getSocial(common), [common])

  return (
    <s.Footer>
      <s.Container>
        <s.TopContainer>
          <s.LogoWrapper href={HASHEX_URL}>
            Logo
            <img src={logo} alt="logo" />
          </s.LogoWrapper>

          <s.Socials>
            {socials.map((social) => (
              <s.Social key={social.alt}>
                <a
                  href={
                    isEmail(social?.href)
                      ? `mailto:${social.href}`
                      : `${social.href}`
                  }
                  target={isEmail(social?.href) ? '_self' : '_blank'}
                  rel="nofollow noreferrer"
                  onClick={social.onClick}
                >
                  {social.icon}
                </a>
              </s.Social>
            ))}
          </s.Socials>

          <s.PoliciesDesktop>
            <PoliciesComponent common={common} />
          </s.PoliciesDesktop>
        </s.TopContainer>
        <div>
          <s.Navigation>
            <s.Audits>
              {linksComponent({
                title: common?.auditsTitle,
                links: common?.auditsLinks,
              })}
            </s.Audits>
            <s.AuditsTypes>
              {linksComponent({
                title: common?.typesOfAuditsTitle,
                links: common?.typesOfAuditsLinks,
              })}
            </s.AuditsTypes>
            <s.Products>
              {linksComponent({
                title: common?.productsTitle,
                links: common?.productsLinks,
              })}
            </s.Products>
            <s.Services>
              {linksComponent({
                title: common?.servicesTitle,
                links: common?.servicesLinks,
              })}
            </s.Services>
            <s.Company>
              {linksComponent({
                title: common?.companyTitle,
                links: common?.companyLinks,
              })}
            </s.Company>
          </s.Navigation>
          <s.Chains>
            {linksComponent({
              title: common?.chainsTitle,
              links: common?.chainsLinks,
            })}
          </s.Chains>
          <s.PoliciesMobile>
            <PoliciesComponent common={common} />
          </s.PoliciesMobile>
        </div>
      </s.Container>
    </s.Footer>
  )
}

export default Footer

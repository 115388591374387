import axios from 'axios'
import qs from 'qs'

const PUBLIC_API = process.env.REACT_APP_PUBLIC_API

export class StrapiService {
  static get = async (
    page: string,
    params?: string[],
    filters?: any,
    pagination?: any,
    sort?: string[],
  ) => {
    const response = await axios.get(`${PUBLIC_API}${page}`, {
      params: {
        populate: params,
        filters,
        pagination,
        sort,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })

    return response.data
  }
  static getCommon = async () => {
    const {
      data: { attributes },
    } = await StrapiService.get('common', [
      'auditsLinks',
      'chainsLinks',
      'companyLinks',
      'productsLinks',
      'servicesLinks',
      'typesOfAuditsLinks',
      'logoTicker',
      'logoTicker.items',
      'logoTicker.items.logo',
      'faq',
      'faq.items',
      'faq.items.questions',
      'contacts',
      'chainsBlock',
      'chainsBlock.chains',
      'chainsBlock.chains.chainImage',
      'requestForm',
      'requestForm.fields',
      'requestForm.fields.options',
      'orderForm',
      'orderForm.fields',
      'products',
      'products.items',
      'products.items.logo',
      'media',
      'media.items',
      'media.items.logo',
      'otherServices',
      'companyInfoItems',
      'companyInfoItems.backgroundImage',
      'companyInfoItems.logoPic',
      'statistic',
      'statistic.securedFunds',
      'statistic.auditedContractsNumber',
      'statistic.experience',
      'statistic.projectsNumber',
      'statistic.customersNumber',
    ])

    return attributes
  }
}

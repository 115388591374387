import styled, { keyframes } from 'styled-components'

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #2c2c2c;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }
`

import { useState, useEffect } from 'react'

import { common as defaultCommon } from '../constants/common'
import { StrapiService } from '../services/strapi.service'

export type Contacts = {}

export type MenuLink = {
  id: number
  hasArrow?: boolean | null
  linkHref: string
  linkTitle: string
}

export type Common = {
  auditsLinks: MenuLink[]
  auditsTitle: string
  productsHref: string
  contacts: Contacts
  telegramChatLink: string
  socialEmailLink: string
  socialMediumLink: string
  socialTwitterLink: string
  socialLinkedinLink: string
  socialTelegramLink: string
  socialGithubLink: string
  copyrightText: string
  privacyPolicyTitle: string
  cookiePolicyTitle: string
  defiBadgeLink: string
  typesOfAuditsTitle: string
  typesOfAuditsLinks: MenuLink[]
  productsTitle: string
  productsLinks: MenuLink[]
  servicesTitle: string
  servicesLinks: MenuLink[]
  companyTitle: string
  companyLinks: MenuLink[]
  chainsTitle: string
  chainsLinks: MenuLink[]
}

export const useCommon = (): { common: Common; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [common, setCommon] = useState<Common>(defaultCommon)
  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true)
        const common = await StrapiService.getCommon()
        setCommon(common)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    }

    fetch()
  }, [])
  return {
    isLoading,
    common,
  }
}

import React from 'react'

import GoogleTagService from '../../services/gtag.service'

import SVG from '../../components/Svg'

export const getSocial = (common: any) => [
  {
    alt: 'medium logo',
    href: common?.socialMediumLink,
    onClick: () => GoogleTagService.pushSocialMediaClick('medium'),
    icon: (
      <SVG width="24" height="24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#414141" />
        <g clipPath="url(#a)" fill="#fff">
          <path d="M12.827 12.474c0 1.97-1.638 3.566-3.658 3.566S5.51 14.444 5.51 12.474c0-1.97 1.638-3.567 3.659-3.567 2.02 0 3.658 1.597 3.658 3.567ZM16.84 12.473c0 1.855-.818 3.358-1.829 3.358-1.01 0-1.829-1.503-1.829-3.358 0-1.854.82-3.357 1.83-3.357 1.01 0 1.829 1.503 1.829 3.357ZM18.483 12.474c0 1.661-.288 3.008-.644 3.008-.355 0-.643-1.347-.643-3.008 0-1.662.288-3.008.643-3.008.356 0 .644 1.347.644 3.008Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(1.846 5.538)"
              d="M0 0h20.308v13.846H0z"
            />
          </clipPath>
        </defs>
      </SVG>
    ),
  },
  {
    alt: 'twitter logo',
    href: common?.socialTwitterLink,
    onClick: () => GoogleTagService.pushSocialMediaClick('twitter'),
    // icon: (
    //     <SVG width="24" height="24" fill="none">
    //         <circle cx="12" cy="12" r="12" fill="#414141" />
    //         <path
    //             d="m11.84 9.163.024.439-.4-.054c-1.457-.206-2.73-.905-3.81-2.08l-.528-.582-.136.43c-.288.96-.104 1.972.496 2.653.32.377.248.43-.304.207-.192-.072-.36-.126-.376-.099-.056.063.136.878.288 1.201.208.448.632.887 1.096 1.147l.392.206-.464.01c-.448 0-.464.008-.416.197.16.582.792 1.2 1.497 1.47l.496.188-.432.287a4.176 4.176 0 0 1-2.145.663c-.36.009-.657.045-.657.072 0 .09.977.591 1.545.788 1.705.583 3.73.332 5.25-.663 1.081-.708 2.162-2.115 2.666-3.478.272-.726.544-2.052.544-2.689 0-.412.024-.466.472-.959.264-.286.513-.6.56-.69.08-.17.072-.17-.336-.018-.68.27-.776.233-.44-.17.248-.287.544-.807.544-.959 0-.027-.12.018-.256.099-.144.09-.464.224-.704.304l-.432.153-.392-.296c-.216-.161-.52-.34-.68-.394-.409-.126-1.033-.108-1.401.035-1 .404-1.633 1.443-1.561 2.582Z"
    //             fill="#fff"
    //         />
    //     </SVG>
    // ),
    icon: (
      <SVG width="24" height="24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#414141" />
        <path
          d="M16.0258 6H18.1726L13.4825 11.0831L19 18H14.6799L11.2962 13.8049L7.42452 18H5.27646L10.2929 12.5631L5 6H9.4298L12.4883 9.83446L16.0258 6ZM15.2724 16.7815H16.4619L8.78344 7.15446H7.50693L15.2724 16.7815Z"
          fill="white"
        />
      </SVG>
    ),
  },
  {
    alt: 'linkedin logo',
    href: common?.socialLinkedinLink,
    onClick: () => GoogleTagService.pushSocialMediaClick('linkedin'),
    icon: (
      <SVG width="24" height="24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#414141" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.655 6.976c0 .799-.602 1.438-1.568 1.438H7.07c-.93 0-1.531-.64-1.531-1.438 0-.816.62-1.438 1.567-1.438s1.53.622 1.549 1.438ZM8.473 9.55v8.323h-2.77V9.55h2.77Zm9.988 8.322V13.1c0-2.556-1.366-3.746-3.19-3.746-1.47 0-2.128.808-2.496 1.375v-1.18h-2.77c.036.782 0 8.323 0 8.323h2.77v-4.648c0-.248.018-.497.091-.675.2-.496.656-1.01 1.422-1.01 1.002 0 1.403.762 1.403 1.88v4.453h2.77Z"
          fill="#fff"
        />
      </SVG>
    ),
  },
  {
    alt: 'github logo',
    href: common?.socialGithubLink,
    onClick: () => GoogleTagService.pushSocialMediaClick('github'),
    icon: (
      <SVG width="24" height="24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#414141" />
        <path
          d="M12 3.692c-4.588 0-8.308 3.814-8.308 8.518 0 3.764 2.38 6.957 5.682 8.083.415.079.567-.185.567-.41 0-.203-.007-.874-.01-1.585-2.312.515-2.8-1.005-2.8-1.005-.378-.985-.922-1.247-.922-1.247-.754-.529.057-.518.057-.518.834.06 1.273.878 1.273.878.741 1.302 1.944.926 2.418.708.074-.55.29-.926.527-1.139C8.64 15.76 6.7 15.03 6.7 11.765c0-.93.325-1.69.856-2.286-.086-.214-.37-1.08.08-2.254 0 0 .698-.229 2.286.873A7.778 7.778 0 0 1 12 7.811a7.798 7.798 0 0 1 2.08.287c1.586-1.102 2.283-.873 2.283-.873.452 1.173.167 2.04.081 2.254.533.597.855 1.357.855 2.287 0 3.271-1.943 3.992-3.793 4.202.298.265.563.783.563 1.578 0 1.14-.01 2.057-.01 2.337 0 .227.15.493.571.409 3.3-1.128 5.677-4.32 5.677-8.082 0-4.704-3.72-8.518-8.308-8.518Z"
          fill="#fff"
        />
        <path
          d="M6.863 15.864c-.018.042-.083.054-.142.025-.06-.027-.094-.084-.074-.126.018-.043.083-.055.143-.027.06.028.094.086.073.128ZM7.2 16.245c-.04.037-.118.02-.17-.04-.054-.058-.065-.137-.024-.175.04-.037.115-.02.17.04.054.059.065.137.023.175ZM7.526 16.73c-.05.037-.133.003-.185-.072-.05-.075-.05-.165.001-.2.052-.037.134-.005.186.07.05.076.05.166-.002.203ZM7.974 17.2c-.045.051-.142.038-.213-.032-.072-.068-.093-.165-.047-.215.046-.052.143-.037.215.032.072.068.094.165.045.215ZM8.592 17.472c-.02.066-.113.096-.207.068-.094-.029-.155-.106-.136-.173.02-.066.113-.098.207-.068.094.03.155.106.136.173ZM9.27 17.523c.003.07-.076.127-.175.128-.1.002-.18-.054-.18-.122 0-.07.077-.128.177-.13.098-.002.179.054.179.123ZM9.902 17.414c.012.068-.057.137-.154.156-.097.018-.186-.024-.198-.091-.012-.07.058-.14.154-.158.098-.017.186.024.198.093Z"
          fill="#161514"
        />
      </SVG>
    ),
  },
  {
    alt: 'mail logo',
    href: common?.socialEmailLink,
    onClick: () => GoogleTagService.pushSocialMediaClick('hashex_email'),
    icon: (
      <SVG width="24" height="24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#414141" />
        <path
          d="M17.6 7H6.4c-.77 0-1.393.563-1.393 1.25L5 15.75c0 .688.63 1.25 1.4 1.25h11.2c.77 0 1.4-.563 1.4-1.25v-7.5C19 7.562 18.37 7 17.6 7Zm0 2.5L12 12.625 6.4 9.5V8.25l5.6 3.125 5.6-3.125V9.5Z"
          fill="#F1F1F1"
        />
      </SVG>
    ),
  },
  {
    alt: 'telegram logo',
    href: common?.socialTelegramLink,
    onClick: () => GoogleTagService.pushSocialMediaClick('telegram'),
    icon: (
      <SVG width="24" height="24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#414141" />
        <path
          d="m17.964 8.018-1.972 9.305c-.154.664-.512.84-1.073.527l-2.987-2.224-1.45 1.405c-.193.195-.396.292-.608.292l.231-3.072 5.54-5.033c.135-.137.135-.215 0-.234-.117-.04-.242-.01-.378.087l-6.843 4.36-2.958-.936c-.33-.097-.484-.244-.464-.439.019-.195.222-.36.609-.497l11.512-4.507c.29-.097.522-.058.696.117.174.156.223.44.145.849Z"
          fill="#F1F1F1"
        />
      </SVG>
    ),
  },
]

import React from "react";

import SVG from "../Svg";

const Sun = ({ className }: { className?: string }) => (
    <SVG width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
<path d="M5.3 4.03325L3.8 2.54158L2.625 3.71659L4.11667 5.20825L5.3 4.03325ZM0.5 8.74992H3V10.4166H0.5V8.74992ZM8.83333 0.458252H10.5V2.91659H8.83333V0.458252ZM15.5333 2.53742L16.7067 3.70992L15.215 5.20158L14.0425 4.02825L15.5333 2.53742ZM14.0333 15.1333L15.525 16.6333L16.7 15.4583L15.2 13.9666L14.0333 15.1333ZM16.3333 8.74992H18.8333V10.4166H16.3333V8.74992ZM9.66667 4.58325C6.90833 4.58325 4.66667 6.82492 4.66667 9.58325C4.66667 12.3416 6.90833 14.5833 9.66667 14.5833C12.425 14.5833 14.6667 12.3416 14.6667 9.58325C14.6667 6.82492 12.425 4.58325 9.66667 4.58325ZM9.66667 12.9166C7.825 12.9166 6.33333 11.4249 6.33333 9.58325C6.33333 7.74158 7.825 6.24992 9.66667 6.24992C11.5083 6.24992 13 7.74158 13 9.58325C13 11.4249 11.5083 12.9166 9.66667 12.9166ZM8.83333 16.2499H10.5V18.7083H8.83333V16.2499ZM2.625 15.4499L3.8 16.6249L5.29167 15.1249L4.11667 13.9499L2.625 15.4499Z" fill="#7C7C7C"/>
</SVG>
);

export default Sun;
